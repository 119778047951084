import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscriber } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Product } from 'src/app/modals/product.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { map, filter } from 'rxjs/operators';
import { analyzeAndValidateNgModules, ElementSchemaRegistry } from '@angular/compiler';
import { Category } from 'src/app/modals/category.model';
import { environment } from 'src/environments/environment';



// Get product from Localstorage
let products = JSON.parse(localStorage.getItem("compareItem")) || [];

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public currency : string = 'USD';
  public catalogMode : boolean = false;

  private _url: string = "assets/data/";
  public url = "assets/data/banners.json";

  baseUrl: string = environment.base_url;

  private tabP:Product[]=[];
  private tabC: Category[] = [];
  private tabtest:Product[]=[ {uid: "fb28583b-982c-41d4-a4df-1ddb73829779", nom: "Montre", description: "clean", prix: 25000},
  {uid: "5c73acf3-326e-4908-94fe-7fa1feb02ccd", nom: "Habib Adidas", description: "Inbox", prix: 15000}];

  public compareProducts : BehaviorSubject<Product[]> = new BehaviorSubject([]);
  public observer   :  Subscriber<{}>;

  constructor(private httpClient: HttpClient, public snackBar: MatSnackBar) {
   this.compareProducts.subscribe(products => products = products)

   this.products()
   this.categorie()
   

   // this.testercat("329f1eeb-0e90-4fa6-9faa-30a66a472863")
   //this.getProductIdCat("d6b7e66f-01a9-4618-894f-008e7cac32ba")



  // this.getProducts()
  }


  public testProduct(): Observable<any> {

    return this.httpClient.get<any>(`${this.baseUrl}products`)
 }

  private products() {

     this.httpClient.get<Product[]>(`${this.baseUrl}products`).subscribe(donne=>{
        donne.forEach(res=>{
         //console.log('product',res)
          this.tabP.push(res)
       })
     })

    // console.log("test tab in func:",this.tabP)
     return this.tabP
  }


  // Get Category

  public categorie(){
    const headers = new HttpHeaders({'content-type': 'application/json'})
    return this.httpClient.get(`${this.baseUrl}categories`, {headers});
  }

  public banners(): Observable<any[]>{
    return this.httpClient.get<any[]>(this.url);
  }


    // Get Banners
    public getBanners() {
      return this.banners();
    }

    // Get Banners
    public getProducts(){
      return this.tabP
    }

    // Get Banners
    public getCategorie() {
      return this.categorie();
    }


      // Get Products By Id
  public getProduct(id: string) {

   const f = this.getProducts().find(item=>item.uid === id)

    
    return f


    /*.pipe(map(items => {
      return items.find((item: Product) =>
        { return item.uid === id; });
      }));*/
    // return this.products.find(product=> product.id === id);

    // return this.httpClient.get<Product>(this._url + 'product-' + id + '.json');
  }

   // Get Products By Idcat
   public getProductIdCat(id: string) {

    const f = this.products().find(item=>item.category === id)

     //console.log('testtttt: ',f)
     return f


   }
        /*
      ---------------------------------------------
      ----------  Compare Product  ----------------
      ---------------------------------------------
   */

// Get Compare Products
public getComapreProducts(): Observable<Product[]> {
  const itemsStream = new Observable(observer => {
    observer.next(products);
    observer.complete();
  });
  return <Observable<Product[]>>itemsStream;
}

// If item is aleready added In compare
public hasProduct(product: Product): boolean {
  const item = products.find(item => item.id === product.uid);
  return item !== undefined;
}

 // Add to compare
 public addToCompare(product: Product): Product | boolean {
  let message, status;
  var item: Product | boolean = false;
  if (this.hasProduct(product)) {
    item = products.filter(item => item.uid === product.uid)[0];
    const index = products.indexOf(item);
    this.snackBar.open('The product  ' + product.nom + ' already added to comparison list.', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });

  } else {
    if(products.length < 4)
      products.push(product);
      message = 'The product ' + product.nom + ' has been added to comparison list.';
      status = 'success';
      this.snackBar.open(message, '×', { panelClass: [status], verticalPosition: 'top', duration: 3000 });

  }
    localStorage.setItem("compareItem", JSON.stringify(products));
    return item;
}

// Removed Product
public removeFromCompare(product: Product) {
  if (product === undefined) { return; }
  const index = products.indexOf(product);
  products.splice(index, 1);
  localStorage.setItem("compareItem", JSON.stringify(products));
}

   // Get Products By category
  public getProductByCategory(category:string) {
    
    return this.testProduct()

  }


    // Get Products By category
   public getProductB(category: string): Observable<Product[]> {
    return this.testProduct().pipe(map(items =>
       items.filter((item: Product) => {
         if(category == 'efd2408f-55e9-4ae7-82d2-c9b00758e8bb')
            return item
            else
            return item.category === category;

       })
     ));
  }
  

}
