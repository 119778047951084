import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { ActivatedRoute, Params } from '@angular/router';
import { Product, ColorFilter } from 'src/app/modals/product.model';

@Component({
  selector: 'app-product-left-sidebar',
  templateUrl: './product-left-sidebar.component.html',
  styleUrls: ['./product-left-sidebar.component.sass']
})
export class ProductLeftSidebarComponent implements OnInit {
  public sidenavOpen:boolean = true;
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public tagsFilters  :   any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public colorFilters :   ColorFilter[] = [];

  
  public items        :   Product[] = [];
  public allItems: any[] = [];
  public fin: any[] = [];
  public products: any[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];

  constructor(private productService: ProductService, private route: ActivatedRoute) {

    this.route.params.subscribe(
      (params: Params) => {
        const category = params['category'];
        this.productService.getProductB(category).subscribe(produit => {
          this.allItems=[]
          produit.forEach(item => {
            this.allItems.push(item)
          })
          console.log("table:::",this.allItems)
        })
      }
    )

    // this.route.params.subscribe(
    //   (params: Params) => {
    //     const category = params['category'];
    //     console.log("id", category)
        
    //     this.productService.getProductByCategory(category).subscribe(products => {

          
    //       products.forEach(res => {

            

    //         this.allItems.push(res);
            
    //         this.fin=[]
    //       this.fin =  this.allItems.filter(res => {
    //           if (res.category === category) {
    //               return res
    //           } else {
    //             return res.category=="efd2408f-55e9-4ae7-82d2-c9b00758e8bb"
    //           }
    //         })
    //       })
    //       console.log("produit by id_sous_cat",products)
         
    //      this.products = products.slice(0.8);
    //      this.getTags(products)
    //      this.getColors(products)
    //     })
    //   }
    // )

    // this.route.params.subscribe(
     
    //   (params: Params) => {
    //     const category = params['category'];
    //     console.log('categorie:', category)
    //      let itemss: any = [];
    //     this.productService.getProductByCategory(category).subscribe(productss => {
          
    //       itemss = productss.filter(product => {
    //         if (product.category===category) {
    //           return product
    //         } else {
    //           return product.category=="efd2408f-55e9-4ae7-82d2-c9b00758e8bb"
    //         }
            
    //     })

    //       console.log('produit ts', itemss)
    //         this.allItems.push(itemss);
    //         console.log('dans ts', this.allItems)
    //         this.products = itemss.slice(0.8);
    //         this.getTags(itemss)
    //         this.getColors(itemss)
         
    //     })
    //   }
    // )
    //console.log('dans consr', this.allItems)
  }





     // Get current product tags
     public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }

  ngOnInit() {

    
    
  }


  
  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
    // Animation Effect fadeIn
    public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

    // Update tags filter
    public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

     // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });

}

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
}


  // Update price filter
//   public updatePriceFilters(price: any) {
//     let items: any[] = [];
//     this.products.filter((item: Product) => {
//         if (item.price >= price[0] && item.price <= price[1] )  {
//            items.push(item); // push in array
//         }
//     });
//     this.items = items;
// }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


   this.allItems = this.products.filter((item: Product) => {
     return item.prix >= price.priceFrom && item.prix <= price.priceTo
    });
     console.log(this.products);

}

onBrendsChanged(newBrend) {
  console.log(newBrend);
  this.allItems = newBrend === '06638415-afdd-41ce-aaa4-d93ba984a9e6' ? this.products : this.products.filter(

    item => item.brand === newBrend
  )
  console.log(this.allItems);


}
}
